<template>
  <div>
    <registerCompany ref="registerCompany"></registerCompany>
  </div>
</template>

<script>
import registerCompany from "~/baseComponents/registerCompany";
export default {
  components: {
    registerCompany,
  },
  mounted(){
    this.$refs.registerCompany.openPanel()
  }
};
</script>

<style>
</style>